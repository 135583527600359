import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import AnimationWrapper from "../components/animationWrapper"
import ContactSection from "../components/contactSection"
import ContactMap from "../components/contactMap"
import Layout from "../components/layout"

import "./kontakt.css"

const ContactPage = ({data, location}) => {

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      {/* <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper> */}

      <section id="contact-data" className="page-section full-width">
        <ContactSection data={data.page.frontmatter.contact_section[0]} />
      </section>

      <ContactMap mapUrl={data.page.frontmatter.contact_map_url} />

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "kontakt" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        contact_section{
          title
          description
          type
          fields
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        contact_map_url
      }
    }
  }
`

export default ContactPage
